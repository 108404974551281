<template>
  <container>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>Moments</h1>
        </grid-column>
        <grid-column eight right-aligned>
          <dropdown
            name="Create Moment"
            default-text="+ Create Moment"
            button
            button-variant="primary"
            hide-selection
            hide-caret
            @update="addMoment"
          >
            <dropdown-item value="template">
              📓 Create from Template
            </dropdown-item>
            <dropdown-item value="scratch">
              ✏️ Create from Scratch
            </dropdown-item>
          </dropdown>
        </grid-column>
      </grid>
    </page-header>
    <loading v-if="pageLoading || formLoading" :loading="true" />
    <template v-else>
      <cards>
        <card
          v-for="moment in moments"
          :key="moment.id"
          class="moment-card"
          fluid
          cards
          borderless
          :to="{ name: 'moment', params: { id: moment.id } }"
        >
          <moment-icon
            :hex-color="moment.color"
            :icon="moment.icon"
            size="massive"
          />
          <card-body>
            <card-header>
              <h3>{{ moment.name }}</h3>
            </card-header>
            <card-description> {{ moment.description }}</card-description>
            <g-label v-if="moment.isActive" green attached="top right">
              Active
            </g-label>
          </card-body>
          <card-footer>
            <i class="user icon" />
            {{ moment.enrolledCount }}
            {{ moment.enrolledCount === 1 ? 'Person' : 'People' }}
          </card-footer>
        </card>
      </cards>
      <placeholder
        v-if="moments.length === 0"
        size="large"
        class="placeholder"
        centered
        :src="momentsFiller"
        @click.native="$refs.modal.show()"
      >
        Create your first moment workflow
      </placeholder>

      <pagination
        v-if="momentsPagination && momentsPagination.lastPage > 1"
        :num-of-pages="momentsPagination.lastPage"
      />
    </template>
    <modal ref="modal" size="tiny" @deny="reset">
      <modal-header> Create Moment </modal-header>
      <modal-content>
        <g-form id="create-moment-form" @submit="create">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isFormValid = value)"
            />

            <form-field>
              <form-label> Name </form-label>
              <form-input
                name="Name"
                :rules="['required', 'max:255']"
                :initial-value="newMoment.name"
                placeholder="The Sorting Ceremony"
                @update="(value) => (newMoment.name = value)"
              />
            </form-field>
            <form-field>
              <form-label> Description </form-label>
              <form-input
                name="Description"
                :rules="['max:255']"
                :initial-value="newMoment.description"
                placeholder="An annual ritual for all new Hogwarts students"
                @update="(value) => (newMoment.description = value)"
              />
            </form-field>
            <form-field-group equal-width>
              <form-field>
                <form-label>Color</form-label>
                <form-dropdown
                  name="Color"
                  required
                  :initial-value="newMoment.color || momentColors[0]"
                  default-text="Select Color"
                  @update="(value) => (newMoment.color = value)"
                >
                  <dropdown-item
                    v-for="color in momentColors"
                    :key="color"
                    :value="color"
                  >
                    <i
                      class="fas fa-square form-dropdown-icon"
                      :style="{ color: color }"
                    />
                  </dropdown-item>
                </form-dropdown>
              </form-field>
              <form-field>
                <form-label>Icon</form-label>
                <form-dropdown
                  name="Icon"
                  required
                  :initial-value="newMoment.icon || momentIcons[0]"
                  default-text="Select Icon"
                  @update="(value) => (newMoment.icon = value)"
                >
                  <dropdown-item
                    v-for="icon in momentIcons"
                    :key="icon"
                    :value="icon"
                  >
                    <i :class="[icon, 'form-dropdown-icon']" />
                  </dropdown-item>
                </form-dropdown>
              </form-field>
            </form-field-group>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn variant="default" type="reset" form-id="create-moment-form">
          Cancel
        </form-btn>

        <form-btn
          variant="primary"
          type="submit"
          :invalid="!isFormValid"
          form-id="create-moment-form"
        >
          Create
        </form-btn>
      </modal-actions>
    </modal>
    <modal ref="redirect">
      <modal-content>
        <p>{{ redirectMessage }}</p>
        <btn variant="primary" @click.native="redirectToDev">
          Go back to dev
        </btn>
      </modal-content>
    </modal>
  </container>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formFieldGroup from '@/components/v2/form/field_group.vue'
import formInput from '@/components/v2/form/input.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formLabel from '@/components/v2/form/label.vue'
import formValidation from '@/components/v2/form/validation.vue'
import formDropdown from '@/components/v2/form/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'
import cards from '@/components/v2/card/cards.vue'
import card from '@/components/v2/card/card.vue'
import cardBody from '@/components/v2/card/body.vue'
import cardHeader from '@/components/v2/card/header.vue'
import cardDescription from '@/components/v2/card/description.vue'
import cardFooter from '@/components/v2/card/footer.vue'
import gLabel from '@/components/v2/label.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import momentsFiller from '@/assets/img/momentsFiller.png'
import btn from '@/components/v2/btn.vue'

import { momentColors } from '@/assets/js/colors'
import { momentIcons } from '@/assets/js/icons'

export default {
  components: {
    loading,
    container,
    pagination,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formFieldGroup,
    formInput,
    formBtn,
    formValidation,
    formLabel,
    formDropdown,
    dropdownItem,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
    cards,
    card,
    cardBody,
    cardDescription,
    cardFooter,
    cardHeader,
    gLabel,
    momentIcon,
    dropdown,
    btn,
  },
  data() {
    return {
      momentsFiller,
      moments: [],
      momentsPagination: null,
      pageLoading: false,
      formLoading: false,
      newMoment: {
        name: '',
        description: '',
        icon: '',
        color: '',
      },
      isFormValid: false,
      showCreateOptions: false,
      momentColors,
      momentIcons,
      redirectMessage: '',
    }
  },
  watch: {
    $route: {
      handler() {
        this.getMoments()
      },
    },
  },
  mounted() {
    this.checkIfRedirectedFromDev()
    this.checkIfUserIsOnboarding()
    this.getMoments()
  },
  methods: {
    checkIfUserIsOnboarding() {
      if (
        this.$store.state.currentUser &&
        !this.$store.state.currentUser.organizationId
      ) {
        this.$router.push({ name: 'sign_up_onboarding' })
      }
    },
    async getMoments() {
      try {
        this.pageLoading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            enrolledCount: 1,
            sort: 'isActive-desc,isActiveAt-desc,name,id',
            userStatus: ['active', 'onboarding'],
          }
        )
        this.moments = response.data
        this.momentsPagination = response.cursor
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    addMoment(option) {
      if (option === 'scratch') {
        this.$refs.modal.show()
        return
      }
      if (option === 'template') {
        this.$router.push({ name: 'templates' })
        return
      }
      throw new TypeError(`got unexpected option ${option}`)
    },
    reset() {
      this.newMoment = {
        name: '',
        description: '',
        icon: '',
        color: '',
      }
    },
    async create(submission) {
      try {
        this.formLoading = true
        const result = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`,
          {
            name: submission.get('Name'),
            description: submission.get('Description'),
            icon: submission.get('Icon'),
            color: submission.get('Color'),
            type: 'date',
            organizationId: this.$store.state.organizationId,
          }
        )
        this.$router.push({
          name: 'moment',
          params: {
            id: result.data.id,
          },
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.formLoading = false
      }
    },
    checkIfRedirectedFromDev() {
      if (
        this.$route.query.code &&
        this.$route.query.scope === 'https://www.googleapis.com/auth/calendar'
      ) {
        this.redirectMessage =
          "You've been redirected here because you were testing the gCal integration in dev. Click below to go back to dev."
        this.$refs.redirect.show()
      }
    },
    redirectToDev() {
      const query = window.location.href.split('?')[1]
      window.location.href = 'http://localhost:8080/settings?'.concat(query)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';

.card.moment-card {
  max-width: 14em;
}

.form-dropdown-icon {
  font-size: @large-icon-font-size;
  line-height: 1;
}
</style>
