const momentColors = [
  '#FFD9CB',
  '#FFDABC',
  '#FFF6CC',
  '#DBF4C7',
  '#C9E9EF',
  '#DBFFF3',
  '#E1DBFF',
  '#FFDBFF',
  '#DCDDDE',
]
module.exports = { momentColors }
