const momentIcons = [
  'fas fa-certificate',
  'fas fa-baby',
  'birthday cake icon',
  'far fa-calendar-check',
  'far fa-calendar-plus',
  'user plus icon',
  'fas fa-user-minus',
  'fas fa-user-friends',
  'play circle outline icon',
  'fas fa-plane-departure',
  'fas fa-glass-cheers',
  'fas fa-gift',
  'far fa-address-card',
  'fas fa-graduation-cap',
  'fas fa-mug-hot',
  'far fa-compass',
  'fas fa-map-marked-alt',
  'fas fa-magic',
  'fas fa-heartbeat',
  'fas fa-award',
  'fas fa-flag-checkered',
  'fas fa-rocket',
  'fas fa-dollar-sign',
  'fas fa-map-signs',
  'fas fa-bullhorn',
  'far fa-bell',
  'fas fa-people-carry',
  'fas fa-chart-line',
  'fas fa-clinic-medical',
  'fas fa-home',
  'far fa-building',
  'fas fa-bullseye',
  'fas fa-mountain',
  'fas fa-tasks',
  'fas fa-poll',
]

module.exports = { momentIcons }
