<template>
  <validation-provider v-slot="{ errors }" :rules="required ? 'required' : ''">
    <input v-model="value" :name="name" type="hidden" />
    <dropdown
      :name="name"
      :initial-value="value"
      selection
      fluid
      :clearable="clearable"
      :search="search"
      :loading="loading"
      :default-text="defaultText"
      :error="!!errors[0]"
      @update="(value_) => (value = value_)"
      @search="(value) => $emit('search', value)"
    >
      <slot />
    </dropdown>
    <span class="error">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { isEqual } from 'lodash'

import { ValidationProvider } from '@/extensions/v2/validation'
import dropdown from '@/components/v2/dropdown/dropdown.vue'

export default {
  components: { ValidationProvider, dropdown },
  props: {
    name: {
      type: String,
      required: true,
    },
    defaultText: {
      type: String,
      required: true,
    },
    initialValue: {
      type: [String, Number, Object],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  watch: {
    initialValue: {
      handler(newValue) {
        this.value = newValue
      },
      immediate: true,
    },
    value: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.$emit('update', newValue)
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.error {
  color: @error-red;
}
</style>
